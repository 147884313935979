import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ReportTable } from '../../components/library'
import theme from '../../theme'

const data = {
    header: [
        'FEV1',
        'FVC',
        'FEV1/FVC'
    ],
    rows: [
        {
            label: '% predicted',
            cells: {
                'FEV1': {value: '97%', level: 1},
                'FVC': {value: '115%', level: 4},
                'FEV1/FVC': {value: '-', level: 0}
            }
        },
        {
            label: 'actual value',
            cells: {
                'FEV1': {value: '4.93L', level: 3},
                'FVC': {value: '5.89L', level: 1},
                'FEV1/FVC': {value: '0.84', level: 2}
            }
        },
    ]
}


function section() {
    return (
        <LibraryWrapper>
            <ReportTable data={data} />
        </LibraryWrapper>
    )
}
export default section
